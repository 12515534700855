@charset "UTF-8";
* {
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  color: #5e5b62;
}

.exercise-header {
  background-color: #0079d1;
  color: #f9f8f7;
  text-align: center;
  padding: 1rem;
  margin: 2rem 0;
}
.exercise-header h1 {
  font-size: 24px;
}

.testimonial-container {
  color: #5e5b62;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin: auto;
  padding: 10px;
}
@media (min-width: 768px) {
  .testimonial-container {
    flex-direction: row;
    justify-content: space-around;
  }
}
@media (min-width: 1025px) {
  .testimonial-container {
    width: 75%;
  }
}
@media (min-width: 1201px) {
  .testimonial-container {
    width: 53%;
  }
}
.testimonial-container div {
  position: relative;
  border-left: 2px solid #fb6e05;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 25px;
  margin-left: 20px;
}
.testimonial-container div:before {
  content: "“"; /* Unicode code for opening quotation mark */
  font-family: "Times New Roman", Times, serif;
  font-size: 45px;
  line-height: 1.3;
  color: white;
  background-color: #fb6e05;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid white;
  position: absolute;
  top: -22px;
  left: -22px;
  width: 40px;
  height: 40px;
}
.testimonial-container div:after {
  content: "";
  background-color: #fb6e05;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: -1px;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
}
.testimonial-container div p {
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
}
.testimonial-container div span {
  font-size: 10pt;
  font-weight: 700;
}
.testimonial-container a {
  background-color: #0079d1;
  transition: background-color 0.3s ease;
  color: white;
  text-transform: uppercase;
  font-size: 10pt;
  font-weight: 700;
  white-space: nowrap;
  border-radius: 4px;
  padding: 25px 40px;
}
.testimonial-container a:hover {
  cursor: pointer;
  background-color: #0596ff;
}

.media-page {
  border: 1px solid #5e5b62;
  width: 95%;
  margin: auto;
  margin-bottom: 20px;
}

.filters-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  padding-bottom: 0;
}
@media (min-width: 501px) {
  .filters-container {
    flex-direction: row;
  }
}
@media (min-width: 768px) {
  .filters-container {
    flex-direction: column;
  }
}

.media-filters {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}
@media (min-width: 501px) {
  .media-filters {
    justify-content: space-around;
  }
}
@media (min-width: 768px) {
  .media-filters {
    flex-direction: row;
    justify-content: space-between;
  }
}

.select-filters {
  display: flex;
  padding-top: 10px;
}

.other-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
@media (min-width: 501px) {
  .other-filters {
    align-items: center;
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .other-filters {
    flex-direction: row;
    align-items: flex-end;
  }
}
.other-filters button {
  background-color: transparent;
  color: #0079d1;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 10pt;
  font-weight: 700;
  text-decoration: underline;
  border: none;
  padding: 10px;
  margin: 15px;
}
.other-filters button:hover {
  cursor: pointer;
}
.other-filters button:focus {
  background-color: #eeebe8;
  border-color: #0079d1;
}

.dropdown-filter {
  display: inline-block;
  width: 50%;
}
@media (min-width: 501px) {
  .dropdown-filter {
    margin-right: 20px;
    width: 50%;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: #f0f0f0;
  color: #5e5b62;
  font-family: "Montserrat", sans-serif;
  font-size: 12pt;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid #5e5b62;
  border-width: 0 0 2px 0;
  position: relative;
  height: 30px;
  width: 140px;
  padding: 20px 10px;
  cursor: pointer;
}
.dropdown-toggle span {
  padding-left: 10px;
}

.dropdown.open .dropdown-options {
  border: 1px solid #5e5b62;
  visibility: visible;
  max-height: 300px;
}
.dropdown.open:before,
.dropdown.open:after {
  content: "";
  display: inline-block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  position: absolute;
  width: 0;
  height: 0;
  top: 43px;
  left: 25px;
  margin: 0;
  padding: 0;
  z-index: 2;
}
.dropdown.open:before {
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #5e5b62;
  top: 42px;
  left: 23px;
}

.dropdown-options {
  background-color: #fff;
  display: block;
  visibility: hidden;
  position: absolute;
  max-height: 0px;
  overflow-y: auto;
  margin-top: 10px;
  transition: max-height 0.3s ease;
  z-index: 2;
}

.dropdown-options label {
  text-transform: uppercase;
  font-size: 10pt;
  font-weight: 700;
  white-space: nowrap;
  display: inline-block;
  border-bottom: 1px solid #5e5b62;
  position: relative;
  padding: 10px;
  width: 100%;
  cursor: pointer;
}
.dropdown-options label:hover {
  background-color: #eeebe8;
}
.dropdown-options input[type="checkbox"]:focus + label {
  background-color: #eeebe8;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -mox-apperance: none;
  display: inline-block;
  border: 1px solid #5e5b62;
  border-radius: 4px;
  vertical-align: middle;
  width: 17px;
  height: 17px;
  margin-right: 10px;
}
input[type="checkbox"]:checked:before {
  content: "";
  border: solid #5e5b62;
  border-width: 0 3px 3px 0;
  position: absolute;
  left: 16px;
  top: 50%;
  width: 5px;
  height: 9px;
  margin-left: 1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 1;
}

.toggle-container {
  display: flex;
  flex-direction: row;
  padding: 15px 0;
}
@media (min-width: 501px) {
  .toggle-container {
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .toggle-container {
    flex-direction: row;
  }
}
.toggle-container label {
  font-size: 10pt;
  font-weight: 700;
  padding: 5px;
}

.search-container {
  position: relative;
}

.search-bar {
  width: 100%;
  height: 2.5rem;
  padding: 10px;
}
@media (min-width: 768px) {
  .search-bar {
    width: 250px;
  }
}

.search-icon {
  position: absolute;
  transform: translateY(30%);
  right: 8px;
  color: #5e5b62;
}

.filter-pills {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  padding-top: 0;
}

.filter-pill-button {
  background-color: #f0f0f0;
  color: #5e5b62;
  font-family: "Montserrat", sans-serif;
  font-size: 9pt;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: solid #5e5b62;
  border-width: 0 0 2px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 1.5rem;
  margin-right: 8px;
  margin-bottom: 5px;
  cursor: pointer;
}
.filter-pill-button span {
  padding-left: 5px;
}

.media-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid #5e5b62;
  padding: 10px 5px;
}
@media (min-width: 501px) {
  .media-list {
    flex-direction: row;
    align-items: normal;
  }
}

.media-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 95%;
}
@media (min-width: 501px) {
  .media-card {
    width: 50%;
  }
}
@media (min-width: 1025px) {
  .media-card {
    width: 33%;
  }
}
@media (min-width: 1201px) {
  .media-card {
    width: 25%;
  }
}
.media-card img {
  width: 95%;
}

.card-text {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 95%;
  margin-bottom: 10%;
}
.card-text p {
  font-size: 10pt;
  font-weight: 700;
  margin: 0;
}
.card-text h2 {
  font-size: 13pt;
  font-weight: 700;
  margin-bottom: 5px;
}

.no-results {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  padding: 100px;
}

